/* src/components/Itinerary.css */
.itinerary {
    font-family: monospace;
    margin: 20px;
    line-height: 1.6;
    height: 50vh;
    overflow-y: scroll;
}

/* .welcome-text {
    height: 50vh;
    overflow-y: auto;
    font-family: monospace;
    text-align: justify;
    color: white;
    background-color: #152238; 
    border-radius: 5px;
    margin-bottom: 5px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
} */

.welcome-text h1 {
    color: lightblue;
    text-align: center;
    font-family: monospace;
    font-size: xx-large;
    margin-top:2%;
}

.welcome-text h2 {
    color: white;
    text-align: left;
    font-family: monospace;
    font-size: xx-large;
    margin: 0 auto;
    margin-top:2%;
    width: 80%;
}

.welcome-text p {
    color:white;
    text-align: justify;
    width: 80%;
    font-weight:lighter;
    font-size: normal;
    margin-left: auto;
    margin-right: auto;
}

.welcome-text ul {
    margin: 20px auto;
}

.welcome-text li {
    color: white ;
    font-weight:lighter;
    font-size: normal;
    text-align: left;
    margin: 0 auto;
    width: 70%;
}

.day {
    margin-bottom: 20px;
}

.day h2 {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
}

.day ul {
    list-style-type: none;
    padding-left: 0;
}

.day h3 {
    color: white;
}

.day {
    background-color: #152238; 
    /* background-image: linear-gradient(180deg, rgba(255, 255, 255, .25), rgba(255, 255, 255, 0.1)); */
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 10px;
}

.itinerary-box {
    margin-top: 10px;
}